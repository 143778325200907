/* eslint-disable */
import React from 'react';

const Timer = ({onFinish = () => {}, timer = 3600}) => {
  let [timerClock, setTimerClock] = React.useState(timer);

  const minutes = Math.floor(timerClock / 60);
  const seconds = timerClock % 60;

  React.useEffect(() => {
    if (timerClock === 0) {
      onFinish();
    } else {
      const timer = setTimeout(function () {
        setTimerClock(timerClock - 1);
      }, 1000);
      return () => {
        // this should work flawlessly besides some milliseconds lost here and there
        clearTimeout(timer);
      };
    }
  }, [timerClock]);

  const renderMinutes = minutes.toString().length < 2 ? `0${minutes}` : minutes;
  const renderSeconds = seconds.toString().length < 2 ? `0${seconds}` : seconds;

  return (
    <p>
      {renderMinutes}:{renderSeconds} left
    </p>
  );
};

export default Timer;
