/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import ReactToPrint from 'react-to-print';

import { OtherColor, PrimaryColor, SecondaryColor, WhiteColor } from '../../Assets/colorPalette';
import { listeningAnswerKey, readingAnswerKey } from './Components/PreTestComponents/AnswerKey';
import { listeningAnswerKey as listeningAnswerKeyPostTest, readingAnswerKey as readingAnswerKeyPostTest } from './Components/PostTestComponents/AnswerKey';

const TEST_TYPE = {
  PRE_TEST: 'Pre Test',
  POST_TEST: 'Post Test',
};

const _renderTableContent = (
  listeningAnswerArray,
  listeningAnswer,
  readingAnswerArray,
  readingAnswer,
  writingAnswerArray,
  testType,
) => {
  const getListeningAnswerKey = testType === TEST_TYPE.PRE_TEST ? listeningAnswerKey : listeningAnswerKeyPostTest;
  const getReadingAnswerKey = testType === TEST_TYPE.PRE_TEST ? readingAnswerKey : readingAnswerKeyPostTest;

  return (
    <Table bordered>
      <tbody>
        <tr>
          <td className="text-center" colSpan={3}>
            <h4>
              <b> Listening</b>
            </h4>
          </td>
        </tr>
        <tr>
          <td className="text-center">
            <b> No</b>
          </td>
          <td className="text-center">
            <b> Answer</b>
          </td>
          <td className="text-center" width="50%">
            <b> Answer Key</b>
          </td>
        </tr>
        {listeningAnswerArray?.map((data, index) => {
          let newIndex = index + 1;
          return (
            <tr className="text-center" key={index}>
              <td>{newIndex}</td>
              <td>{convertAnswer(listeningAnswer[newIndex])}</td>
              <td>{convertAnswer(getListeningAnswerKey[newIndex])}</td>
            </tr>
          );
        })}
        <tr>
          <td className="text-center" colSpan={3}>
            <h4>
              <b> Reading</b>
            </h4>
          </td>
        </tr>
        <tr>
          <td className="text-center">
            <b> No</b>
          </td>
          <td className="text-center">
            <b> Answer</b>
          </td>
          <td className="text-center" width="50%">
            <b> Answer Key</b>
          </td>
        </tr>
        {readingAnswerArray?.map((data, index) => {
          let newIndex = index + 1;
          return (
            <tr className="text-center" key={index}>
              <td>{newIndex}</td>
              <td>{convertAnswer(readingAnswer[newIndex])}</td>
              <td>{convertAnswer(getReadingAnswerKey[newIndex])}</td>
            </tr>
          );
        })}
        <tr>
          <td className="text-center" colSpan={3}>
            <h4>
              <b> Writing</b>
            </h4>
          </td>
        </tr>
        <tr>
          <td className="text-center">
            <b> No</b>
          </td>
          <td className="text-center" colSpan={2}>
            <b> Answer</b>
          </td>
        </tr>
        {writingAnswerArray?.map((data, index) => {
          let newIndex = index + 1;
          return (
            <tr key={index}>
              <td className="text-center">{newIndex}</td>
              <td colSpan={2}>
                <p className="text-justify text-pre-line">{writingAnswerArray[index]}</p>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const convertAnswer = (answer) => {
  if (Array.isArray(answer)) {
    return answer.map((data, index) => {
      if (index + 1 === answer.length) {
        return `${data}`;
      } else {
        return `${data}, `;
      }
    });
  } else {
    return answer;
  }
};

const ScoreView = () => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [show, setShow] = useState(false);

  const [listeningAnswer, setListeningAnswer] = useState([]);
  const [readingAnswer, setReadingAnswer] = useState([]);

  const [listeningAnswerArray, setListeningAnswerArray] = useState([]);
  const [readingAnswerArray, setReadingAnswerArray] = useState([]);
  const [writingAnswerArray, setWritingAnswerArray] = useState([]);

  const [isDataReadyForPrint, setIsDataReadyForPrint] = useState(false);

  const [testType, setTestType] = useState(TEST_TYPE.PRE_TEST);

  useEffect(() => {
    // This useEffect hook will be used to update the ReactToPrint component when the data is ready for print.
    if (isDataReadyForPrint && componentRef.current) {
      componentRef.current.handlePrint();
      setIsDataReadyForPrint(false);
    }
  }, [isDataReadyForPrint]);

  const componentRef = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetch = async () => {
    const {
      data: {
        data: { scoreView }
      }
    } = await axios.get('https://api.inggo.id/inggo');
    setData(scoreView);
  };

  const deleteData = async (id) => {
    try {
      await axios.delete('https://api.inggo.id/inggo/' + id);
      await fetch();
    } catch (e) {
      console.log(e);
    }
  };

  const convertTimeStamp = (time) => {
    return new Date(time).toISOString().slice(0, 19).replace('T', ' ');
  };

  useEffect(() => {
    fetch();
  }, []);

  const formattedData = (item) => {
    const formatData = JSON.parse(item?.answer);
    const { listeningAnswer, readingAnswer } = formatData;

    const writingAnswer = [item?.firstWriting, item?.secondWriting];

    setListeningAnswer(listeningAnswer);
    setReadingAnswer(readingAnswer);

    let tempListeningArray = [];
    let tempReadingArray = [];
    for (let i = 1; i <= 40; i++) {
      tempListeningArray.push(listeningAnswer[i]);
      tempReadingArray.push(readingAnswer[i]);
    }

    setListeningAnswerArray(tempListeningArray);
    setReadingAnswerArray(tempReadingArray);
    setWritingAnswerArray(writingAnswer);
  };

  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} className="p-5">
        {_renderTableContent(
          listeningAnswerArray,
          listeningAnswer,
          readingAnswerArray,
          readingAnswer,
          writingAnswerArray,
          testType,
        )}
      </div>
    );
  });

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => <>{row.email}</>
    },
    {
      name: 'Phone Number',
      selector: (row) => row.notelp,
      sortable: true
    },
    {
      name: 'Test',
      selector: (row) => row.test,
      sortable: true,
      cell: (row) => (
        <>
          {row?.test === 'Pre Test' ? (
            <span className="btn btn-sm btn-info text-light">{row?.test}</span>
          ) : (
            <span className="btn btn-sm btn-primary">{row?.test}</span>
          )}
        </>
      )
    },
    {
      name: 'Listening',
      selector: (row) => row.listening,
      sortable: true,
    },
    {
      name: 'Reading',
      selector: (row) => row.reading,
      sortable: true,
    },
    {
      name: 'Time',
      selector: (row) => row.createdAt,
      sortable: true,
      cell: (row) => convertTimeStamp(row.createdAt)
    },
    {
      name: '#',
      cell: (row) => (
        <>
          <i
            className="bi bi-eye"
            onClick={() => {
              setTestType(row.test);
              setSelected(row);
              formattedData(row);
              handleShow();
            }}
            style={{
              color: WhiteColor,
              backgroundColor: PrimaryColor,
              padding: 5,
              borderRadius: '8px',
              cursor: 'pointer',
              marginRight: '2px'
            }}
          />
          <ReactToPrint
            trigger={() => (
              <i
                className="bi bi-download"
                style={{
                  color: WhiteColor,
                  backgroundColor: OtherColor,
                  padding: 5,
                  borderRadius: '8px',
                  cursor: 'pointer',
                  marginRight: '2px'
                }}
              />
            )}
            onBeforeGetContent={() => {
              return new Promise((resolve) => {
                setSelected(row);
                formattedData(row);
                resolve()
              });
            }}
            content={() => componentRef.current}
          />
          <i
            className="bi bi-trash"
            onClick={() => {
              window.confirm('Are you sure you wish to delete this item?')
                ? deleteData(row?.id)
                : () => {
                    console.log('');
                  };
            }}
            style={{
              color: WhiteColor,
              backgroundColor: SecondaryColor,
              padding: 5,
              borderRadius: '8px',
              cursor: 'pointer'
            }}
          />
        </>
      )
    }
  ];

  return (
    <>
      <div className="p-5" style={{ minHeight: '75vh' }}>
        <DataTable columns={columns} data={data} pagination />
      </div>
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={componentRef} />
      </div>
      <Modal show={show} onHide={handleClose} animation={false} size="lg" fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>
            {selected?.name} ({selected?.test})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected &&
            _renderTableContent(
              listeningAnswerArray,
              listeningAnswer,
              readingAnswerArray,
              readingAnswer,
              writingAnswerArray
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScoreView;
