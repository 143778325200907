/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';

import { ContainerTest } from './style';
import CodeCheckComponent from './Components/CodeCheck.component';

import ListeningContainer from './Components/PostTestComponents/Listening/Listening.container';
import ReadingContainer from './Components/PostTestComponents/Reading/Reading.container';
import WritingContainer from './Components/PostTestComponents/Writing/Writing.container';

import { listeningAnswerKey, readingAnswerKey } from './Components/PostTestComponents/AnswerKey';
import { convertListeningScore, convertReadingScore, validateEmail } from '../../Assets/utils';
import { SecondaryColor, WhiteColor } from '../../Assets/colorPalette';
import axios from 'axios';
import { CenterDiv } from '../Home/Contact/Contact.styled';

const PAGE = {
  AUTH: 1,
  LISTENING: 2,
  READING: 3,
  WRITING: 4,
  COUNTING_ANSWER: 5
};

const PostTestContainer = () => {
  const [page, setPage] = useState(PAGE.AUTH);
  const [fillFormPage, setFillFormPage] = useState(true);

  const listeningAnswer = useSelector((state) => state.answer.postTestListening);
  const writingAnswer = useSelector((state) => state.answer.postTestWriting);
  const readingAnswer = useSelector((state) => state.answer.postTestReading);

  const [totalListeningAnswer, setTotalListeningAnswer] = useState(0);
  const [totalReadingAnswer, setTotalReadingAnswer] = useState(0);

  const [listeningAnswerArray, setListeningAnswerArray] = useState([]);
  const [readingAnswerArray, setReadingAnswerArray] = useState([]);

  const [profile, setProfile] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  const renderAnswerToArray = () => {
    let tempListeningArray = [];
    let tempReadingArray = [];
    for (let i = 1; i <= 40; i++) {
      tempListeningArray.push(listeningAnswer[i]);
      tempReadingArray.push(readingAnswer[i]);
    }
    setListeningAnswerArray(tempListeningArray);
    setReadingAnswerArray(tempReadingArray);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    renderAnswerToArray();
  }, []);

  const convertAnswer = (answer) => {
    if (Array.isArray(answer)) {
      return answer.map((data, index) => {
        if (index + 1 === answer.length) {
          return `${data}`;
        } else {
          return `${data}, `;
        }
      });
    } else {
      return answer;
    }
  };

  const sendResults = (listening, reading, answer) => {
    const payload = {
      name: profile.name,
      email: profile.email,
      notelp: profile.phone,
      listening,
      reading,
      answer: JSON.stringify(answer),
      firstWriting: writingAnswer[1],
      secondWriting: writingAnswer[2],
      test: 'Post Test'
    };

    axios
      .post('https://api.inggo.id/inggo', payload)
      .then(() => { })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsSubmitting(false);
        setFillFormPage(false);
      });
  };

  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} className="p-5">
        <h4 className="text-center">
          <b> Post-Test Result</b>
        </h4>
        <hr />
        <Row>
          <Col md={4} sm={4}>
            <b>Listening Score</b>
          </Col>
          <Col md={1} sm={1}>
            :
          </Col>
          <Col>{convertListeningScore(totalListeningAnswer)}</Col>
        </Row>
        <Row className="mt-2">
          <Col md={4} sm={4}>
            <b>Reading Score</b>
          </Col>
          <Col md={1} sm={1}>
            :
          </Col>
          <Col>{convertReadingScore(totalReadingAnswer)}</Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <Col md={6} sm={6}>
            <Table bordered>
              <tbody>
                <tr>
                  <td colSpan={3} className="text-center">
                    <b> Listening Answer</b>
                  </td>
                </tr>
                <tr className="text-center">
                  <td>
                    <b> No</b>
                  </td>
                  <td>
                    <b> Answer</b>
                  </td>
                  <td>
                    <b> Answer Key</b>
                  </td>
                </tr>
                {listeningAnswerArray?.map((data, index) => {
                  let newIndex = index + 1;
                  return (
                    <tr className="text-center">
                      <td>{newIndex}</td>
                      <td>{convertAnswer(listeningAnswer[newIndex])}</td>
                      <td>{convertAnswer(listeningAnswerKey[newIndex])}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
          <Col md={6} sm={6}>
            <Table bordered>
              <tbody>
                <tr>
                  <td colSpan={3} className="text-center">
                    <b> Reading Answer</b>
                  </td>
                </tr>
                <tr className="text-center">
                  <td>
                    <b> No</b>
                  </td>
                  <td>
                    <b> Answer</b>
                  </td>
                  <td>
                    <b> Answer Key</b>
                  </td>
                </tr>
                {readingAnswerArray?.map((data, index) => {
                  let newIndex = index + 1;
                  return (
                    <tr className="text-center">
                      <td>{newIndex}</td>
                      <td>{convertAnswer(readingAnswer[newIndex])}</td>
                      <td>{convertAnswer(readingAnswerKey[newIndex])}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4} sm={4}>
            <b>Writing</b>
          </Col>
          <Col md={1} sm={1}>
            :
          </Col>
          <Col>
            <Row>
              <Col md={12} sm={12}>
                <b>Task 1</b> <br />
                <span className="text-pre-line">
                  {writingAnswer[1]}
                </span>
              </Col>
              <Col className="mt-5" md={12} sm={12}>
                <b>Task 2</b> <br />
                <span className="text-pre-line">
                  {writingAnswer[2]}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className="mt-5">
          <Col md={12} sm={12} className="mt-5">
            <b>Writing Task 1 - sample answer</b> <br />
            The pie charts provide information about how much water is used for various purposes in
            six areas worldwide. <br />
            <br />
            Overall, in all areas, except North America and Europe, water utilized for agriculture
            is proportionately higher than that used for other purposes. Additionally, households
            consume the least amount of water in all areas. <br />
            <br />
            In terms of agricultural use, more than 80% of water in three areas (Central Asia,
            Africa, and Southeast Asia) is consumed at 88%, 84%, and 81%, respectively.
            Additionally, South America uses slightly lower at 71%. The remaining areas, North
            America and Europe, utilize even less at around 30%-40% of their water for agriculture.{' '}
            <br />
            <br />
            Regarding industrial use, European factories account for half of the total amount of
            water, reaching 53%, followed by North America at 48%. The other areas use a minimal
            proportion of their water for this particular purpose ranging from 5% to 12%. <br />
            <br />
            Lastly, domestic makes up less than 20% of water use in the six areas. More
            specifically, 19% of the total water consumed by South American households is marginally
            above that in Europe (15%) and North America (13%). The households in the rest of the
            countries only use a mere 7% to 9%. <br />
          </Col>
          <Col className="mt-5" md={12} sm={12}>
            <b>Writing Task 2 - sample answer</b> <br />
            As the world is becoming more globalized, many languages have been wiped out, and others
            are currently facing extinction. Some say losing languages is not vital because having
            fewer languages makes life easier. However, I disagree with the view as losing languages
            brings about more adverse effects on society, both culturally and economically. <br />
            <br />
            One key argument why such extinction could be detrimental is that cultures are lost
            along with the loss of particular languages. This is because languages are tightly
            coupled with cultures, and they both represent a country’s national identity. If more
            languages were lost, the diversity of world cultures would be at stake because more
            countries would become more homogeneous, offering the same culture and language. <br />
            <br />
            Another point to consider is that having fewer languages would negatively impact global
            tourism, especially in countries that rely heavily on the tourism industry. As people
            often travel for new and unique experiences, many might not opt for countries where
            their language is spoken, resulting in the declining number of tourists visiting those
            countries, and this might imperil its economic growth in the long run. <br />
            <br />
            Finally, if fewer languages were spoken in the world, many jobs would be lost.
            Language-related occupations such as interpreters, translators, or language teachers
            would no longer exist, leaving millions of people unemployed. Consequently, this would
            harm people’s livelihood and welfare in the long run. <br />
            <br />
            In conclusion, while some people view losing languages as a trivial issue, I believe its
            harmful effects should not be overlooked. Having numerous languages shows the diversity
            our world owns, and thus they should be preserved. <br />
          </Col>
        </Row>
      </div>
    );
  });

  const componentRef = useRef();

  const checkingAnswer = () => {
    let listeningAnswerTemp = 0;
    let readingAnswerTemp = 0;

    for (let i = 1; i <= 40; i++) {
      if (listeningAnswer[i] !== '') {
        if (i === 21) {
          let temp = '';
          for (let a = 0; a < listeningAnswer[i].length; a++) {
            if (
              listeningAnswerKey[i].includes(listeningAnswer[i][a]) &&
              temp !== listeningAnswer[i][a]
            ) {
              temp = listeningAnswer[i][a];
              listeningAnswerTemp += 1;
            }
          }
        } else if (Array.isArray(listeningAnswerKey[i])) {
          for (let j = 0; j < listeningAnswerKey[i].length; j++) {
            if (listeningAnswerKey[i][j].includes(listeningAnswer[i].toLowerCase())) {
              listeningAnswerTemp += 1;
              break;
            }
          }
        } else {
          if (listeningAnswer[i].toLowerCase() === listeningAnswerKey[i].toLowerCase()) {
            listeningAnswerTemp += 1;
          }
        }
      }

      if (readingAnswer[i] !== '') {
        if (i === 24) {
          let temp = '';
          for (let a = 0; a < readingAnswer[i].length; a++) {
            if (readingAnswerKey[i].includes(readingAnswer[i][a]) && temp !== readingAnswer[i][a]) {
              temp = readingAnswer[i][a];
              listeningAnswerTemp += 1;
            }
          }
        } else {
          if (Array.isArray(readingAnswerKey[i])) {
            for (let k = 0; k < readingAnswerKey[i].length; k++) {
              if (readingAnswerKey[i][k].includes(readingAnswer[i].toLowerCase())) {
                readingAnswerTemp += 1;
                break;
              }
            }
          } else {
            if (readingAnswer[i].toLowerCase() === readingAnswerKey[i].toLowerCase()) {
              readingAnswerTemp += 1;
            }
          }
        }
      }
    }

    setTotalReadingAnswer(readingAnswerTemp);
    setTotalListeningAnswer(listeningAnswerTemp);

    sendResults(
      convertListeningScore(listeningAnswerTemp),
      convertReadingScore(readingAnswerTemp),
      {
        listeningAnswer,
        readingAnswer
      }
    );
  };

  return (
    <ContainerTest>
      {page === PAGE.AUTH && (
        <CodeCheckComponent handleNextPage={handleNextPage} dispatch={dispatch} />
      )}
      {page === PAGE.LISTENING && (
        <ListeningContainer handleNextPage={handleNextPage} dispatch={dispatch} />
      )}
      {page === PAGE.READING && (
        <ReadingContainer handleNextPage={handleNextPage} dispatch={dispatch} />
      )}
      {page === PAGE.WRITING && (
        <WritingContainer handleNextPage={handleNextPage} dispatch={dispatch} />
      )}
      {page === PAGE.COUNTING_ANSWER && (
        <>
          <div style={{ display: 'none' }}>
            <ComponentToPrint ref={componentRef} />
          </div>

          {fillFormPage && (
            <Row className="p-4">
              <Col md={6} className="mx-auto">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          name: e.target.value
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          email: e.target.value
                        });
                      }}
                    />
                    <Form.Text className="text-muted">
                      Make sure you enter correct email format
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPhone">
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone number"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          phone: e.target.value
                        });
                      }}
                    />
                  </Form.Group>
                  <CenterDiv>
                    <Button
                      style={{
                        backgroundColor: SecondaryColor,
                        color: WhiteColor,
                        border: 'none',
                        borderRadius: '100px',
                        padding: '11px 42px'
                      }}
                      disabled={
                        profile.name === '' ||
                        profile.phone === '' ||
                        profile.email === '' ||
                        !validateEmail(profile.email) ||
                        isSubmitting
                      }
                      onClick={() => {
                        setIsSubmitting(true);
                        checkingAnswer();
                      }}>
                      {isSubmitting ? 'Processing...' : 'See my results'}
                    </Button>
                  </CenterDiv>
                </Form>
              </Col>
            </Row>
          )}

          {!fillFormPage && (
            <>
              <div className="p-5">
                <h4 className="text-center">
                  <b> Post-Test Result</b>
                </h4>
                <hr />
                <Row>
                  <Col md={4} sm={4}>
                    <b>Listening Score</b>
                  </Col>
                  <Col md={1} sm={1}>
                    :
                  </Col>
                  <Col>{convertListeningScore(totalListeningAnswer)}</Col>
                </Row>
                <Row className="mt-2">
                  <Col md={4} sm={4}>
                    <b>Reading Score</b>
                  </Col>
                  <Col md={1} sm={1}>
                    :
                  </Col>
                  <Col>{convertReadingScore(totalReadingAnswer)}</Col>
                </Row>
              </div>
              <Row>
                <Col md={4} className="mx-auto mb-4">
                  <ReactToPrint
                    trigger={() => (
                      <button
                        style={{
                          backgroundColor: SecondaryColor,
                          color: WhiteColor,
                          border: 'none',
                          borderRadius: '100px',
                          padding: '11px 42px'
                        }}
                        onClick={() => checkingAnswer()}>
                        Download Answer Sheet and Answer Key
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </ContainerTest>
  );
};

export default PostTestContainer;
