const listeningAnswerKey = {
  1: 'b',
  2: 'c',
  3: ['15 minute', '15-minute'],
  4: ['third year', '3rd year'],
  5: ['first tuesday', '1st tuesday'],
  6: ['25%', '25 percent', '25 per cent'],
  7: 'room 12',
  8: ['Mrs Waddell', 'waddell', 'Mrs. Waddell'],
  9: 'window dressing',
  10: 'black skirt',
  11: 'c',
  12: 'b',
  13: 'c',
  14: ['a', 'd'],
  15: ['a', 'd'],
  16: '75',
  17: 'evening',
  18: ['4-course dinner', 'four-course dinner', 'four course dinner', 'dinner'],
  19: '52',
  20: 'golf club',
  21: 'weather',
  22: ['environment agency', 'environmental agency'],
  23: 'b',
  24: 'a',
  25: 'c',
  26: 'b',
  27: 'b',
  28: 'c',
  29: 'a',
  30: 'a',
  31: 'australia',
  32: ['flight speed', 'speed'],
  33: 'looking for food',
  34: 'start to fly',
  35: ['full adult size', 'full size', 'fully grown'],
  36: 'leave the nest',
  37: 'die',
  38: 'attach identification rings',
  39: ['note the sex', 'note sex'],
  40: ['general health', 'health']
};

const readingAnswerKey = {
  1: 'e',
  2: 'h',
  3: 'i',
  4: 'd',
  5: 'g',
  6: ['yeast', 'bacteria'],
  7: ['yeast', 'bacteria'],
  8: 'protein',
  9: 'chemical solvents',
  10: ['small holes', 'holes'],
  11: 'false',
  12: 'true',
  13: 'notgiven',
  14: 'a',
  15: 'd',
  16: 'b',
  17: 'c',
  18: 'c',
  19: 'd',
  20: 'c',
  21: 'b',
  22: 'egypt',
  23: 'monks',
  24: 'ptolemy',
  25: ['navigation satellites', 'satellites'],
  26: ['some cars', 'cars'],
  27: 'iv',
  28: 'vii',
  29: 'v',
  30: 'i',
  31: 'iii',
  32: 'b',
  33: 'b',
  34: 'd',
  35: 'a',
  36: 'd',
  37: 'false',
  38: 'notgiven',
  39: 'true',
  40: 'false'
};

export { readingAnswerKey, listeningAnswerKey };
