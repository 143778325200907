const listeningAnswerKey = {
  1: ['the 26th', '26th', '26'],
  2: ['7.00', '7:00'],
  3: 'circle',
  4: ['A21 to A24', 'A21-A24', 'A21-24'],
  5: 'mastercard',
  6: ['3290 5876 4401 2899', '3290587644012899'],
  7: 'whitton',
  8: '42 South',
  9: 'SW2 5GE',
  10: ['headphones', 'earphones'],
  11: 'd',
  12: 'f',
  13: 'i',
  14: 'b',
  15: 'e',
  16: 'a',
  17: 'g',
  18: 'arm band',
  19: ['an ambulance', 'ambulances'],
  20: ['yellow ticket', 'yellow tickets'],
  21: ['b', 'd', 'f'],
  22: ['b', 'd', 'f'],
  23: ['b', 'd', 'f'],
  24: 'a',
  25: 'b',
  26: 'c',
  27: 'a',
  28: 'march',
  29: 'secretary',
  30: 'computer office',
  31: ['coast', ['coasts'], 'shore'],
  32: ['garbage', 'rubbish', 'waste'],
  33: 'summer',
  34: 'fish',
  35: 'checked',
  36: 'boat',
  37: 'camera',
  38: ['released', 'freed'],
  39: 'b',
  40: 'a'
};

const readingAnswerKey = {
  1: 'ii',
  2: 'viii',
  3: 'v',
  4: 'i',
  5: 'iii',
  6: 'ix',
  7: ['new zealand', 'nz carrageen', 'nz carrageens'],
  8: 'agar',
  9: 'seameal',
  10: 'cough mixtures',
  11: 'b',
  12: 'c',
  13: 'a',
  14: 'crochet hook',
  15: ['paired leaflets', 'leaves'],
  16: 'thorn',
  17: ['tapered steps', 'steps'],
  18: 'true',
  19: 'true',
  20: 'false',
  21: 'true',
  22: 'notgiven',
  23: 'false',
  24: ['c', 'd', 'f'],
  25: ['c', 'd', 'f'],
  26: ['c', 'd', 'f'],
  27: 'a',
  28: 'd',
  29: 'c',
  30: 'b',
  31: 'c',
  32: 'b',
  33: 'd',
  34: 'b',
  35: 'a',
  36: 'c',
  37: 'h',
  38: 'l',
  39: 'a',
  40: 'i'
};

export { readingAnswerKey, listeningAnswerKey };
